import React from 'react';
import './Games.css';
import KokisBackground from './images/kokiscatch.png';
import MemoryBackground from './images/memorymatch.png';
import PuzzelBackground from './images/puzzel.png';
const games = [
    {
        name: "Kokis Catch",
        description: "Catch as many kokis as you can before time runs out!",
        link: "https://awurudu.connexit.biz/games/kokiscatch",
        imageUrl: KokisBackground
    },
    // {
    //     name: "Memory Match",
    //     description: "Test your memory with our fun and engaging Memory Match game!",
    //     link: "http://link-to-memorymatch-game.com",
    //     imageUrl: MemoryBackground
    // },
    {
        name: "Puzzel Solve",
        description: "Engage your brain with Puzzle Solve. Unlock new challenges and hone your problem-solving skills!",
        link: "https://awurudu.connexit.biz/games/puzzel",
        imageUrl: PuzzelBackground
    }
    // Add more games as needed
];

const Games = () => {
    return (
        <div className="Gamehomepage-container">
            <header className="Gamehomepage-header">
                <h1 className="Gamehome-Text">Welcome to Connex Game Zone</h1>
                {/* <p className="Gamehome-SubText">Explore our variety of fun and exciting games!</p> */}
            </header>
            <div className="games-container">
                {games.map((game, index) => (
                    <a href={game.link} key={index} className="game-card" style={{ backgroundImage: `url(${game.imageUrl})` }}>
                        <div className="game-info">
                            <h3>{game.name}</h3>
                            <p>{game.description}</p>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Games;
